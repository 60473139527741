import React, {
  FormEvent,
  FunctionComponent,
  useContext,
  useState
} from "react";
import {
  Button,
  Avatar,
  styled,
  Typography,
  Container,
  Link,
  Box,
  CssBaseline,
  TextField
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Redirect } from "react-router-dom";
import { AppRoutes } from "../utils/routes";
import { Api } from "../utils/apiClient";
import { AppContext } from "../store/context";
import { useQuery } from "../utils/hooks";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        PsyFeed
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Form = styled("form")``;

type DispatchProps = {
  login: (email: string, password: string) => void;
};

type StateProps = {
  loggedIn: boolean;
};

const Login: FunctionComponent<StateProps & DispatchProps> = () => {
  const { loggedIn, setLoggedIn } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [rememberMe, setRememberMe] = useState(true);
  const query = useQuery();

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();

    Api.login(email, password).then(value => {
      setLoggedIn(value);
    });
  };

  const getRedirectPath = () => {
    let redirect = query.get("redirect");

    if (redirect && redirect !== "/") {
      return redirect;
    }

    return AppRoutes.PENDING;
  };

  if (loggedIn) {
    return <Redirect to={getRedirectPath()} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: "theme.spacing(8)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar
          sx={{
            margin: "theme.spacing(1)",
            backgroundColor: "theme.palette.secondary.main"
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <Form
          sx={{
            width: "100%", // Fix IE 11 issue.
            marginTop: "theme.spacing(1)"
          }}
          noValidate
          onSubmit={onFormSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
          {/*<FormControlLabel*/}
          {/*  control={*/}
          {/*    <Checkbox*/}
          {/*      value="remember"*/}
          {/*      color="primary"*/}
          {/*      checked={rememberMe}*/}
          {/*      onChange={e => {*/}
          {/*        setRememberMe(e.target.checked);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  label="Remember me"*/}
          {/*/>*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              margin: "theme.spacing(3, 0, 2)"
            }}
            disabled={!email || !password}
          >
            Log In
          </Button>
        </Form>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export { Login };
