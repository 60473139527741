import React, { FunctionComponent, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Api } from "../utils/apiClient";
import { CrawlerLink, FilterOptions } from "../store/types";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import produce from "immer";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Autocomplete } from '@mui/material';

type Props = {};

const newLinkState = {
  id: undefined,
  link: "",
  crawlerType: "bandcamp",
  labelName: "",
  defaultGenre: "",
  ignoreInitial: true
};
const CrawlerSettings: FunctionComponent<Props> = () => {
  const [crawlerLinks, setCrawlerLinks] = useState<CrawlerLink[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState<CrawlerLink>(newLinkState);

  const [crawlerRunning, setCrawlerRunning] = useState(false);
  const [crawlerProgress, setCrawlerProgress] = useState(0);

  useEffect(() => {
    const cancelTokenSource = Api.cancelToken();

    Api.loadCrawlerSettings().then(responseData => {
      setCrawlerLinks(responseData || []);
    });

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    labels: [],
    genres: [],
    totalAlbums: 0
  });

  useEffect(() => {
    const cancelTokenSource = Api.cancelToken();

    Api.fetchFilterOptions().then(value => {
      setFilterOptions(value);
    });

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  function updateCrawlerStatus() {
    Api.crawlerStatus().then(crawlerStatus => {
      setCrawlerRunning(crawlerStatus.running);
      setCrawlerProgress(crawlerStatus.progress);
    });
  }

  useEffect(() => {
    updateCrawlerStatus();
    const intervalId = setInterval(() => {
      updateCrawlerStatus();
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveLink = () => {
    if (selectedLink) {
      Api.saveCrawlerLink(selectedLink).then(responseData => {
        setCrawlerLinks(responseData);
        handleClose();
      });
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Link</TableCell>
              <TableCell>CrawlerType</TableCell>
              <TableCell>Label Name</TableCell>
              <TableCell>Default Genre</TableCell>
              <TableCell>Init Ignore</TableCell>
              <TableCell>
                <IconButton
                  aria-label="new"
                  title="new"
                  onClick={() => {
                    setSelectedLink(newLinkState);
                    setOpen(true);
                  }}
                  size="large">
                  <AddIcon />
                </IconButton>
                <IconButton
                  aria-label="start crawler"
                  title="start crawler"
                  onClick={() => {
                    Api.startCrawler().then(updateCrawlerStatus);
                  }}
                  size="large">
                  {crawlerRunning ? (
                    <CircularProgress
                      color="primary"
                      variant="determinate"
                      size={24}
                      thickness={4}
                      value={crawlerProgress}
                    />
                  ) : (
                    <PlayCircleOutlineIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {crawlerLinks.map(row => (
              <TableRow key={row.link}>
                <TableCell component="th" scope="row">
                  {row.link}
                </TableCell>
                <TableCell>{row.crawlerType}</TableCell>
                <TableCell>{row.labelName}</TableCell>
                <TableCell>{row.defaultGenre}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={row.ignoreInitial}
                    disabled={true}
                    name="initial ignore"
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    title="edit"
                    onClick={() => {
                      setSelectedLink(row);
                      setOpen(true);
                    }}
                    size="large">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="run"
                    title="run single crawler"
                    onClick={() => {
                      if (row.id) {
                        Api.startSingleCrawler(row.id).then(
                          updateCrawlerStatus
                        );
                      }
                    }}
                    size="large">
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Crawler Link</DialogTitle>
        <DialogContent>
          <DialogContentText>Add or edit a crawler link</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="link"
            label="Link"
            type="text"
            fullWidth
            value={selectedLink.link}
            onChange={e => {
              setSelectedLink(
                produce(selectedLink, (draft: CrawlerLink) => {
                  const link = e.target.value;
                  draft.link = link;
                  if (link && link.includes("beatport")) {
                    draft.crawlerType = "beatport";
                  } else {
                    draft.crawlerType = "bandcamp";
                  }
                })
              );
            }}
          />
          <TextField
            margin="dense"
            id="crawlerType"
            label="Crawler Type"
            type="text"
            fullWidth
            value={selectedLink.crawlerType}
            onChange={e => {
              setSelectedLink(
                produce(selectedLink, (draft: CrawlerLink) => {
                  draft.crawlerType = e.target.value;
                })
              );
            }}
          />
          <Box mb={1} mt={1}>
            <Autocomplete
              id="label"
              options={filterOptions.labels}
              freeSolo
              renderInput={params => (
                <TextField
                  {...params}
                  label="Label"
                  margin="dense"
                  required={true}
                  fullWidth={true}
                  name="label"
                />
              )}
              inputValue={selectedLink.labelName}
              onInputChange={(e: any, value: string | null) => {
                setSelectedLink(
                  produce(selectedLink, (draft: CrawlerLink) => {
                    draft.labelName = value || "";
                  })
                );
              }}
            />
          </Box>
          <Box mb={1} mt={2}>
            <Autocomplete
              id="genre"
              options={filterOptions.genres}
              freeSolo
              renderInput={params => (
                <TextField
                  {...params}
                  label="Genre"
                  margin="dense"
                  required={true}
                  fullWidth={true}
                  name="genre"
                />
              )}
              inputValue={selectedLink.defaultGenre}
              onInputChange={(e: any, value: string | null) => {
                setSelectedLink(
                  produce(selectedLink, (draft: CrawlerLink) => {
                    draft.defaultGenre = value || "";
                  })
                );
              }}
            />
          </Box>
          <FormControlLabel
            style={{ marginTop: 2 }}
            control={
              <Checkbox
                checked={selectedLink.ignoreInitial}
                onChange={(e, checked) => {
                  setSelectedLink(
                    produce(selectedLink, (draft: CrawlerLink) => {
                      draft.ignoreInitial = checked;
                    })
                  );
                }}
                name="ignore initial"
              />
            }
            label="Init Ignore"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveLink} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export { CrawlerSettings };
