import React, { FunctionComponent, useContext } from "react";

import { Redirect, useLocation } from "react-router-dom";
import { AppRoutes } from "../../utils/routes";
import { AppContext } from "../../store/context";

let requiresAuth = (ComposedComponent: React.FunctionComponent<any>) => {
  const RequiresAuthComponent: FunctionComponent<any> = ({ props }) => {
    const { pathname } = useLocation();

    const { loggedIn } = useContext(AppContext);

    if (!loggedIn) {
      return <Redirect to={`${AppRoutes.LOGIN}?redirect=${pathname}`} />;
    }

    return <ComposedComponent {...props} />;
  };

  return RequiresAuthComponent;
};

export default requiresAuth
