import React, { FunctionComponent } from "react";
import { Grid } from "@mui/material";

type Props = {
  link?: string;
  icon: string;
  extendedIcon?: string;
  streamingServiceName: string;
  noMargin?: boolean;
};

const StreamingButtonContainer = ({ children }: { children: any }) => {
  return <Grid item>{children}</Grid>;
};
const StreamingButton: FunctionComponent<Props> = ({
  link,
  icon,
  streamingServiceName
}) => {
  if (!link) {
    return null;
  } else {
    return (
      <StreamingButtonContainer>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          href={link}
          target={`psyfeed_${streamingServiceName}`}
          style={{
            display: "inline-block",
            overflow: "hidden",
            background: `url(${icon}) no-repeat center`,
            backgroundSize: "contain",
            width: "20px",
            height: "20px"
          }}
          aria-label={`${streamingServiceName} link`}
        />
      </StreamingButtonContainer>
    );
  }
};

export { StreamingButton };
