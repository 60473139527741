import React, { FunctionComponent } from "react";
import icon from "./EQ.svg";
import iconExtended from "./en_US-dark.png";
import { StreamingButton } from "../StreamingButton";
import { StreamingServices } from "../../../store/types";

type Props = { streamingServices: StreamingServices };

const DeezerButton: FunctionComponent<Props> = ({ streamingServices }) => {
  return (
    <StreamingButton
      link={streamingServices.deezer}
      icon={icon}
      extendedIcon={iconExtended}
      streamingServiceName={"deezer"}
    />
  );
};

export { DeezerButton };
