import React, { FunctionComponent } from "react";
import icon from "./Tidal-icon.svg";
import iconExtended from "./tidal_logo_extended.jpg";
import { StreamingButton } from "../StreamingButton";
import { StreamingServices } from "../../../store/types";

type Props = { streamingServices: StreamingServices };

const TidalButton: FunctionComponent<Props> = ({ streamingServices }) => {
  return (
    <StreamingButton
      link={streamingServices.tidal}
      icon={icon}
      extendedIcon={iconExtended}
      streamingServiceName={"tidal"}
    />
  );
};

export { TidalButton };
