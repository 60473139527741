import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useIsMobile = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return !matches;
};
