import {format, isBefore, parseISO, subMonths} from "date-fns";

export const formatDate = (date: string): string => {
  return format(parseISO(date), "dd MMMM yyyy");
};

export const formatDateShort = (date: string): string => {
  return format(parseISO(date), "dd.MM.yy");
};

export const parseDate = (date: string): Date => {
  return parseISO(date);
};

export const isOlderThanMonth = (date: string) => {
  const dateParsed = parseISO(date);
  return isBefore(dateParsed, subMonths(new Date(), 1));
};

export const currentYear = (): string => {
  return format(new Date(), "yyyy");
};

export const isValidDate = (d: any) => d instanceof Date && !isNaN(d.getTime());