import React, { FunctionComponent } from "react";
import { AppleMusicButton } from "./applemusic/AppleMusicButton";
import { StreamingServices } from "../../store/types";
import { SpotifyButton } from "./spotify/SpotifyButton";
import { TidalButton } from "./tidal/TidalButton";
import { AmazonMusicButton } from "./amazon/AmazonMusicButton";
import { YouTubeButton } from "./youtube/YouTubeButton";
import { DeezerButton } from "./deezer/DeezerButton";
import { Grid } from "@mui/material";

type Props = {
  release: StreamingServices;
  extended?: boolean;
};

export const ExtendedButtonContext = React.createContext<boolean | undefined>(
  false
);

const StreamingButtons: FunctionComponent<Props> = ({ release, extended }) => {
  return (
    <ExtendedButtonContext.Provider value={extended}>
      <Grid container spacing={1}>
        <TidalButton streamingServices={release} />
        <AppleMusicButton streamingServices={release} />
        <SpotifyButton streamingServices={release} />
        <DeezerButton streamingServices={release} />
        <YouTubeButton streamingServices={release} />
        <AmazonMusicButton streamingServices={release} />
      </Grid>
    </ExtendedButtonContext.Provider>
  );
};

export { StreamingButtons };
