import axios, { CancelTokenSource } from "axios";
import {
  CrawledAlbum,
  CrawlerLink,
  CrawlerStatus,
  CreateAlbumRequest,
  FetchReleasesResponse,
  Filter,
  FilterOptions,
  FoundInsertAlbum,
  FoundInsertAlbums,
  PendingAlbum,
  Release,
  WaitingAlbum
} from "../store/types";

const axiosClient = axios;

const Api = {
  cancelToken: (): CancelTokenSource => {
    return axios.CancelToken.source();
  },
  login: (email: string, password: string): Promise<boolean> => {
    return axiosClient
      .post(`/api/login`, {
        email,
        password
      })
      .then(response => {
        return response.status === 200;
      });
  },
  fetchData: (filter: Filter): Promise<FetchReleasesResponse> => {
    return axiosClient
      .get(`/api/albums`, {
        params: {
          offset: filter.offset,
          ...(filter.genres && { genres: filter.genres }),
          ...(filter.labels && { labels: filter.labels })
        }
      })
      .then(response => {
        return response.data;
      });
  },
  downloadImage: (url: string): Promise<string> => {
    return axiosClient
      .get(`/api/admin/img`, {
        params: {
          url
        }
      })
      .then(response => {
        return response.data;
      });
  },
  fetchRelease: (id: string): Promise<Release> => {
    return axiosClient.get(`/api/albums/${id}`).then(response => {
      return response.data;
    });
  },
  fetchFilterOptions: (): Promise<FilterOptions> => {
    return axiosClient.get(`/api/filter-options`).then(response => {
      return response.data;
    });
  },
  albumInsertSearch: (searchTerm: string): Promise<FoundInsertAlbums> => {
    return Api.albumInsertSearchV2(searchTerm);
    // return axiosClient
    //   .get(`/api/v2/admin/albums/search`, {
    //     params: {
    //       q: searchTerm
    //     }
    //   })
    //   .then(response => {
    //     return response.data;
    //   });
  },
  albumInsertSearchV2: (searchTerm: string): Promise<FoundInsertAlbums> => {
    return axiosClient
      .get(`https://itunes.apple.com/search?`, {
        params: {
          term: searchTerm.replace('album:"', "").replace('"', ""),
          country: "US",
          entity: "album"
        }
      })
      .then(response => {
        const foundAlbums = {
          data: {
            albums: response.data.results.map((result: any) => {
              return {
                name: result.collectionName,
                sourceUrl: result.collectionViewUrl,
                artists: [{ name: result.artistName }],
                collectionId: result.collectionId
              } as FoundInsertAlbum;
            })
          }
        };
        console.log("Found albums", foundAlbums);
        return foundAlbums;
      });
  },
  triggerAlbumFill: (collectionId: string): Promise<any> => {
    return axiosClient
      .post(`/api/admin/albums/convert`, {
        url: "" + collectionId,
        country: "DE"
      })
      .then(response => {
        return response.data;
      });
  },
  submitNewAlbum: (album: CreateAlbumRequest): Promise<boolean> => {
    return axiosClient.post("/api/admin/albums", album).then(response => {
      if (response.status === 200) {
        return true;
      } else {
        console.error("Could not submit album", response);
        return false;
      }
    });
  },
  isAuthValid: (): Promise<boolean> => {
    return axiosClient
      .get("/api/admin/ping")
      .then(response => {
        return response.status === 200;
      })
      .catch(e => {
        console.log("Admin ping failed", e);
        return false;
      });
  },
  addWaitingRelease: (
    searchTerm: string,
    foundAlbums: FoundInsertAlbums,
    crawledAlbum?: CrawledAlbum
  ): Promise<boolean> => {
    return axiosClient
      .post("/api/admin/albums/waiting", {
        searchTerm,
        ignoreUrls: foundAlbums.data?.albums.map(
          value => "" + value.collectionId
        ),
        crawledAlbum
      })
      .then(response => {
        if (response.status === 200) {
          return true;
        } else {
          console.error("Could not submit album", response);
          return false;
        }
      });
  },
  loadCrawlerSettings: (): Promise<CrawlerLink[]> => {
    return axiosClient.get("/api/admin/crawler/links").then(response => {
      return response.data;
    });
  },
  saveCrawlerLink: (crawlerLink: CrawlerLink): Promise<CrawlerLink[]> => {
    return axiosClient
      .put("/api/admin/crawler/links", crawlerLink)
      .then(response => {
        return response.data;
      });
  },
  startCrawler: (): Promise<void> => {
    return axiosClient.post("/api/admin/crawler/start").then(response => {
      console.log("Crawler started ", response);
    });
  },
  startSingleCrawler: (id: string): Promise<void> => {
    return axiosClient
      .post(`/api/admin/crawler/start-single?id=${id}`)
      .then(response => {
        console.log("Crawler started ", response);
      });
  },
  crawlerStatus: (): Promise<CrawlerStatus> => {
    return axiosClient
      .get("/api/admin/crawler/status")
      .then(response => {
        return response.data;
      })
      .then(response => {
        return response;
      });
  },
  startWaitingAlbumImport: (): Promise<void> => {
    return axiosClient.post("/api/admin/waiter/start").then(response => {
      console.log("Crawler started ", response);
    });
  },
  waiterStatus: (): Promise<boolean> => {
    return axiosClient
      .get("/api/admin/waiter/status")
      .then(response => {
        return response.data;
      })
      .then(response => {
        return response.running;
      });
  },
  retrieveWaitingAlbums: (): Promise<WaitingAlbum[]> => {
    return axiosClient.get("/api/admin/albums/waiting").then(response => {
      return response.data;
    });
  },
  retrievePendingAlbums: (): Promise<PendingAlbum[]> => {
    return axiosClient.get("/api/admin/albums/pending").then(response => {
      return response.data || [];
    });
  },
  deletePendingAlbums: (albumId: string): Promise<boolean> => {
    return axiosClient
      .delete(`/api/admin/albums/pending/${albumId}`)
      .then(response => {
        return response.data;
      });
  },
  deleteWaitingAlbums: (albumId: string): Promise<boolean> => {
    return axiosClient
      .delete(`/api/admin/albums/waiting/${albumId}`)
      .then(response => {
        return response.data;
      });
  }
};

export { Api };
