import React, { FunctionComponent, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { AppRoutes } from "../utils/routes";
import { AppContext } from "../store/context";
import { Link, useLocation } from "react-router-dom";

type Props = {};

function createButton(currentPath: string, routerPath: string, label: string) {
  return (
    <Box display={"inline-block"} mr={1}>
      <Button
        color={"primary"}
        variant={currentPath === routerPath ? "contained" : "outlined"}
        component={Link}
        to={routerPath}
      >
        {label}
      </Button>
    </Box>
  );
}

const Header: FunctionComponent<Props> = () => {
  const { loggedIn } = useContext(AppContext);
  const { pathname } = useLocation();

  return (
    <Box my={4} component={"header"}>
      <Box mx={"auto"} position={"relative"}>
        <Box textAlign={"center"} color={"primary.main"}>
          <Typography component="h1" variant="h4">
            PsyFeed Admin
          </Typography>
        </Box>
        {loggedIn && (
          <Box mt={2} textAlign={"center"}>
            {createButton(pathname, AppRoutes.PENDING, "P")}
            {createButton(pathname, AppRoutes.INSERT, "I")}
            {createButton(pathname, AppRoutes.WAITING, "W")}
            {createButton(pathname, AppRoutes.CRAWLER, "C")}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { Header };
