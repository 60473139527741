import React, { FunctionComponent } from "react";
import icon from "./Amazonmusiclogo.png";
import iconExtended from "./amazon-Music-Badge.png";
import { StreamingButton } from "../StreamingButton";
import { StreamingServices } from "../../../store/types";

type Props = { streamingServices: StreamingServices };

const AmazonMusicButton: FunctionComponent<Props> = ({ streamingServices }) => {
  return (
    <StreamingButton
      link={streamingServices.amazonMusic}
      icon={icon}
      extendedIcon={iconExtended}
      streamingServiceName={"googlePlay"}
    />
  );
};

export { AmazonMusicButton };
