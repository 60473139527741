import React, { FunctionComponent, useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Login } from "./Login";
import { InsertAlbum } from "./insertAlbum/InsertAlbum";
import { AppRoutes } from "../utils/routes";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import { Api } from "../utils/apiClient";
import requiresAuth from "./utils/requiresAuth";
import { AppContext } from "../store/context";
import { CrawlerSettings } from "./CrawlerSettings";
import { Waiting } from "./Waiting";
import { Pending } from "./Pending";

type StateProps = {
  // loggedIn: boolean;
};
type DispatchProps = {
  // setLoggedIn: (loggedIn: boolean) => void;
};

type Props = StateProps & DispatchProps;

const AdminComponent: FunctionComponent<Props> = () => {
  const { loggedIn, setLoggedIn } = useContext(AppContext);
  useEffect(() => {
    Api.isAuthValid()
      .then(valid => {
        setLoggedIn(valid);
      })
      .catch(() => {
        setLoggedIn(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <div>
        <Switch>
          <Route exact path={`${AppRoutes.HOME}/`}>
            {loggedIn ? (
              <Redirect to={AppRoutes.INSERT} />
            ) : (
              <Redirect to={AppRoutes.LOGIN} />
            )}
          </Route>
          <Route path={AppRoutes.LOGIN} component={Login} />
          <Route
            path={AppRoutes.INSERT}
            component={requiresAuth(InsertAlbum)}
          />
          <Route
            path={AppRoutes.CRAWLER}
            component={requiresAuth(CrawlerSettings)}
          />
          <Route path={AppRoutes.WAITING} component={requiresAuth(Waiting)} />
          <Route path={AppRoutes.PENDING} component={requiresAuth(Pending)} />
        </Switch>
      </div>
    </LocalizationProvider>
  );
};

export default AdminComponent;
