import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { Api } from "../utils/apiClient";
import { WaitingAlbum } from "../store/types";
import Container from "@mui/material/Container";
import {
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppRoutes } from "../utils/routes";
import { useHistory } from "react-router-dom";
import { AppContext } from "../store/context";
import { formatDateShort } from "../utils/date";
import { StreamingButtons } from "./streaming/StreamingButtons";

type Props = {};

const Waiting: FunctionComponent<Props> = () => {
  const history = useHistory();
  const { setSelectedCrawledAlbum } = useContext(AppContext);

  const [waitingAlbums, setWaitingAlbums] = useState<WaitingAlbum[]>([]);
  const [waiterRunning, setWaiterRunning] = useState(false);

  function fetchWaitingAlbums() {
    Api.retrieveWaitingAlbums().then(response => {
      setWaitingAlbums(response || []);
    });
  }

  useEffect(() => {
    const cancelTokenSource = Api.cancelToken();
    fetchWaitingAlbums();
    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const updateWaiterStatus = () => {
    Api.waiterStatus().then(running => {
      setWaiterRunning(running);
    });
  };

  useEffect(() => {
    updateWaiterStatus();
    const intervalId = setInterval(() => {
      updateWaiterStatus();
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getSortedWaitingAlbums = () => {
    return waitingAlbums.sort((a, b) => (a.addedAt < b.addedAt ? 1 : -1));
  };

  const deleteWaitingAlbum = (id?: string, onDeleted?: () => void) => {
    const confirmed = window.confirm("Delete?");
    if (confirmed && id) {
      Api.deleteWaitingAlbums(id).then(() => {
        fetchWaitingAlbums();
        if (onDeleted) {
          onDeleted();
        }
      });
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <TableContainer component={Paper}>
        <Table aria-label="waiting albums">
          <TableHead>
            <TableRow>
              <TableCell>Search Term</TableCell>
              <TableCell>Ignore URLs</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Crawled</TableCell>
              <TableCell>Services</TableCell>
              <TableCell>Publish Date</TableCell>
              <TableCell>Added At</TableCell>
              <TableCell>
                <IconButton
                  aria-label="start import"
                  onClick={() => {
                    Api.startWaitingAlbumImport().then(updateWaiterStatus);
                  }}
                  size="large">
                  {waiterRunning ? (
                    <CircularProgress color="primary" size={24} thickness={4} />
                  ) : (
                    <PlayCircleOutlineIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSortedWaitingAlbums().map(row => (
              <TableRow key={row.searchTerm}>
                <TableCell component="th" scope="row">
                  <Link
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedCrawledAlbum(row.crawledAlbum);
                      history.push(
                        `${AppRoutes.INSERT}?searchTerm=${encodeURIComponent(
                          row.searchTerm
                        )}`
                      );
                    }}
                  >
                    {row.searchTerm}
                  </Link>
                </TableCell>
                <TableCell>
                  {row.ignoreUrls &&
                    row.ignoreUrls.map(value => (
                      <div
                        style={{ maxWidth: 250, overflow: "scroll" }}
                        key={value}
                      >
                        <a
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {value}
                        </a>
                      </div>
                    ))}
                </TableCell>
                <TableCell>
                  {row.crawledAlbum && row.crawledAlbum.label}
                </TableCell>
                <TableCell>
                  {row.crawledAlbum ? (
                    <a
                      href={row.crawledAlbum.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      yes
                    </a>
                  ) : (
                    "no"
                  )}
                </TableCell>
                <TableCell>
                  {row.prefilledAlbum && (
                    <StreamingButtons
                      release={row.prefilledAlbum.streamingServices}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {row.crawledAlbum &&
                    formatDateShort(row.crawledAlbum.datePublished)}
                </TableCell>
                <TableCell>{row.addedAt}</TableCell>
                <TableCell>
                  {/*<IconButton*/}
                  {/*  aria-label="edit"*/}
                  {/*  onClick={() => {*/}
                  {/*    // setSelectedLink(row);*/}
                  {/*    // setOpen(true);*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <EditIcon />*/}
                  {/*</IconButton>*/}
                  {row.id && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        deleteWaitingAlbum(row.id);
                      }}
                      size="large">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export { Waiting };
