import React, { FunctionComponent } from "react";
import { Box } from "@mui/material";
import { currentYear } from "../utils/date";

type Props = {};

const Footer: FunctionComponent<Props> = () => {
  return (
    <Box mt={3} textAlign="center">
      © {currentYear()} PsyFeed
    </Box>
  );
};

export { Footer };
