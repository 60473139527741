import React, { FunctionComponent, useCallback } from "react";
import { Box, Grid, styled } from "@mui/material";
import { useDropzone } from "react-dropzone";

type Props = {
  title?: string;
  onImageUpload: (base64Image: string) => void;
};

const Title = styled("div")({
  // color: "rgba(0, 0, 0, 0.54)",
  marginBottom: "5px"
});

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // let encoded = reader.result!.toString().replace(/^data:(.*,)?/, "");
      // if (encoded.length % 4 > 0) {
      //   encoded += "=".repeat(4 - (encoded.length % 4));
      // }
      resolve(reader.result as string);
    };
    reader.onerror = error => reject(error);
  });

const ImageUploadField: FunctionComponent<Props> = ({
  title,
  onImageUpload
}) => {
  const onDrop = useCallback(
    acceptedFiles => {
      toBase64(acceptedFiles[0]).then(value => {
        onImageUpload(value as string);
      });
    },
    [onImageUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false
  });

  return (
    <Grid item xs={12}>
      {title && <Title>{title}</Title>}
      <Box
        {...getRootProps()}
        sx={{
          width: "100%",
          height: "90px",
          border: "3px dashed #9E9E9E",
          padding: "20px 10px",
          cursor: "pointer",
          transition: "all 0.5s ease-out",
          backgroundColor: isDragActive ? "#3f3f3f" : "#282828"
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Let go to upload this file</p>
        ) : (
          <p>Pull a file here or click to choose</p>
        )}
      </Box>
    </Grid>
  );
};

export { ImageUploadField };
