import React, { FunctionComponent } from "react";
import icon from "./AppleMusic-icon.png";
import extendedIcon from "./US-UK_Apple_Music_lockup_RGB_wht.png";
import { StreamingButton } from "../StreamingButton";
import { StreamingServices } from "../../../store/types";

type Props = { streamingServices: StreamingServices };

const AppleMusicButton: FunctionComponent<Props> = ({ streamingServices }) => {
  return (
    <StreamingButton
      link={streamingServices.appleMusic}
      icon={icon}
      extendedIcon={extendedIcon}
      streamingServiceName={"appleMusic"}
    />
  );
};

export { AppleMusicButton };
