import React from "react";
import { CrawledAlbum } from "./types";

export type ContextState = {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  selectedCrawledAlbum: CrawledAlbum;
  setSelectedCrawledAlbum: (crawledAlbum: CrawledAlbum) => void;
  onlyFreshPending: boolean;
  setOnlyFreshPending: (onlyFreshPending: boolean) => void;
};

export const AppContext = React.createContext<ContextState>({
  loggedIn: false,
  setLoggedIn: () => {},
  // @ts-ignore
  selectedCrawledAlbum: {},
  setSelectedCrawledAlbum: () => {},
  onlyFreshPending: false,
  setOnlyFreshPending: () => {}
});
